.dayDate h2 {
    color: var(--primaryDarkColor);
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dayDate p {
    color: var(--primaryDarkColor);
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.eventBoxWrapper {
    cursor: pointer;
    border: 2px solid var(--secondaryLightColor);
    border-radius: 15px;
}

.eventBoxWrapper:hover {
    border-color: var(--primaryDarkColor);
}

.eventBoxWrapper:hover .dayActions {
    display: block;
}

.eventBox {
    min-height: 250px;
    border-left: 7px solid var(--activeColor);
}

.eventBox .dayActions {
    display: none;
}

.eventBox h2.eventTitle {
    color: var(--primaryDarkColor);
    font-family: 'Raleway', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.eventBox p.eventContent {
    olor: var(--black, #0D0D0D);
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    height: fit-content;
    margin-bottom: 10px;
}

.eventBox p.eventHashtag {
    color: var(--secondaryDarkColor);
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.eventBox .dayActions button {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    border-radius: 50px;
    border: 2px solid var(--black, #0D0D0D);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 11px 25px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.toolTipBox {
    position: absolute;
    left: 82%;
    top: 30px;
    background: #000;
    color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
    line-height: 22px;
}

.toolTipBox::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -8px;
    border-top: 6px solid #000;
    border-right: 6px solid #000;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
}

.eventBox .dayActions button.writePost {
    background: var(--activeColor, #D63F32);
}

.eventBox .dayActions button.personalise {
    background: var(--black, #0D0D0D);
}

.eventBox .dayActions button.copyContent {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    position: relative;
}

.eventBoxWrapper {
    position: relative;
}

.loadingReq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.loadingReq h4 {
    color: var(--activeColor);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.loadingReq p {
    color: var(--black);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@keyframes blink {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        opacity: 0;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        opacity: 1;
    }
}

.dot {
    display: inline-block;
    animation: blink 1.5s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(0);
    }

    50% {
        transform: perspective(400px) rotateY(180deg);
    }

    100% {
        transform: perspective(400px) rotateY(0);
    }
}

.flip-emoji {
    display: inline-block;
    animation: flip 2s infinite;
    transform-style: preserve-3d;
}