.monthTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--primaryLightColor);
}

#month-bar {
  position: sticky;
  top: 0px;
  background-color: #f9fdfe;
  z-index: 999999;
}

.monthTabs .monthTabsItem h2 {
  color: var(--primaryDarkColor);
  font-family: "Raleway", sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.year {
  color: var(--primaryDarkColor);
  font-family: "Inter", sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.monthTabs .monthTabsItem.activeTab {
  border-bottom: 2px solid var(--activeColor);
  padding-bottom: 10px;
  margin-bottom: -2px;
}

.activeTab h2 {
  color: var(--activeColor) !important;
}

.fullMonth {
  color: var(--primaryDarkColor);
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: 2px solid var(--black, #000000);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.groupBtn a {
  font-family: var(--headlinefont);
  background-color: var(--white);
  color: var(--black);
  text-decoration: none;
  padding: 15px 40px;
  font-weight: 700;
  border-color: var(--black);
  border-width: 3px;
  border-style: solid;
  letter-spacing: 0;
  border-radius: 50px;
  text-transform: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
  /* border-radius: 50px;
  box-shadow: none;
  padding: 11px 60px;
  margin-left: 20px;
  border: 2px solid var(--black, #0D0D0D);
  color: var(--black, #0D0D0D); */
}

.groupBtn a:hover {
  box-shadow: 0 8px 5px -5px rgba(0, 0, 0, .25) !important;
  transform: translateY(-10px) !important;
}

.groupBtn a.black {
  font-family: var(--headlinefont);
  background-color: var(--black, #0D0D0D);
  color: var(--white);
  text-decoration: none;
  padding: 15px 20px;
  font-weight: 700;
  border-color: var(--transparent);
  border-width: 0;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
  margin-right: 30px;
  /* border: 2px solid var(--black, #0D0D0D);
  background: var(--black, #0D0D0D);
  color: var(--white, #ffffff); */
}

.topHeader .logo img {
  width: 250px;
}