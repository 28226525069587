@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #fff;
  --black: #000;
  --primaryDarkColor: #000;
  --secondaryDarkColor: #0D0D0D;
  --primaryLightColor: #EAEEF0;
  --secondaryLightColor: #fafcfa;
  --activeColor: #D63F32;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: linear-gradient(180deg, rgba(52, 176, 203, 0.03) 0%, rgba(87, 183, 175, 0.03) 62.5%, rgba(118, 143, 85, 0.03) 100%), #FFF;
}

svg{
  display: inline;
}